<template>
  <div class="main-content q-pa-md">
    <Mural :notificationQuantity="147" :breadcrumbs="breadcrumbs">
      <DefaultBtn titleButton="Salvar" icon="add"/>
    </Mural>

    <main class="row col-12 flex justify-center">
      <div class="content row col-11">
        <div class="content-groups-show">
          <label class="card-id">04 grupos </label>
        </div>
        <div class="content-card row full-width">
          <div class="col-12">
            <div class="column col-12">
              <q-carousel
                  v-model="slide"
                  transition-prev="slide-right"
                  transition-next="slide-left"
                  navigation
                  swipeable
                  animated
                  control-color="default-pink"
                  arrows
                  height="250px"
              >
                <q-carousel-slide :name="page" class="row" v-for="page in pagination" :key="page">
                  <div class="row fit justify-start items-center no-wrap ">
                    <q-card class="card-format q-mr-lg" v-for="item in cards" :key="item.id">
                      <q-card-section class="full-height">
                        <div class="column justify-around full-height">
                          <label class="title-card">{{ item.name }}</label>
                          <label class="info-card q-mt-sm"><b>{{ item.users }}</b> usuários</label>
                          <label class="info-card"><b>{{ item.trails }}</b> trilhas</label>
                          <q-btn class="card-btn shadow-5 self-end"
                                 rounded
                                 color="default-pink"
                                 label="Acessar"
                                 no-caps
                          />
                        </div>
                      </q-card-section>
                    </q-card>
                  </div>
                </q-carousel-slide>
              </q-carousel>
            </div>
          </div>
        </div>
      </div>

      <div class="row flex col-12 q-px-lg">
        <div class="column col-12 ">
          <label class="title-nav-table q-pb-sm"> Todos os tutores</label>
          <div class="content-table flex row">
            <div class="column col-12">
              <Table/>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import Table from "@/components/shared/tables/Table.vue";
import {ref} from "vue";

export default {
  name: "StudentPage",
  components: {
    Mural,
    DefaultBtn,
    Table
  },
  setup() {
    let breadcrumbs = ref(["Tutores"]);
    let pagination = ref(1);
    let filter = ref('');


    let cards = [
      {
        id: 1,
        name: "Atendimento ao Cliente",
        users: 280,
        trails: 2,
      },
      {
        id: 2,
        name: "Vendas",
        users: 280,
        trails: 2,
      },
      {
        id: 3,
        name: "Telemarketing",
        users: 280,
        trails: 2,
      },
      {
        id: 4,
        name: "Marketing e Propaganda",
        users: 280,
        trails: 2,
      },
      {
        id: 5,
        name: "Financeiro",
        users: 280,
        trails: 2,
      },
    ]
    return {
      breadcrumbs,
      cards,
      pagination,
      filter,
      slide: ref(1),

    };
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  > main {
    overflow: auto;
    height: 87%;
  }
}

.content-table {
  height: 80%;
}

.title-card {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 17px;
  color: #000000;
}

.title-nav-table {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.info-card {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: #2F2F2F;
}

.card-id {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  opacity: 0.4;
}

.card-format {
  width: 90%;
  height: 80%;
  border-radius: 10%;
}

.card-format:hover {
  bottom: 7%;
  border: 1px solid #FE5268;
}

.info-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #686868;
}

.btn-size {
  width: 20px;
  border-radius: 100%;
}

.btn-range {
  width: 250px;
}

.title-nav-table {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.card-btn {
  box-shadow: 3px 10px 15px #00000033;
}
</style>